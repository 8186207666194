<template>
  <v-dialog v-model="dialogNoPack" persistent max-width="1500px">
    <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
      <v-card-text style="background-color: #f1f4f9">
        <v-row>
          <v-col cols="12" md="3" class="no-padding ml-n2">
            <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
              <v-card-title>
                <v-img
                  :src="require('@/assets/images/logos/anavel_logo.png')"
                  max-height="150px"
                  max-width="120px"
                  alt="logo"
                  contain
                ></v-img>
              </v-card-title>
              <v-card-title class="text-center justify-center muller-capitalized">Mes Services Assurance</v-card-title>
              <v-card-subtitle class="text-center justify-center">Un logiciel ANAVEL</v-card-subtitle>
              <v-card-text class="no-padding">
                <v-img :src="require('@/assets/images/logos/layer.png')" alt="logo" width="300px" class=""> </v-img>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="9" class="no-padding d-flex">
            <v-card class="flex-fill flex d-flex flex-column" flat>
              <v-card-title class="muller-capitalized EoleBlue--text">
                Bonjour {{ $store.state.user.firstname }}
              </v-card-title>
              <v-card-subtitle>
                Choisissez votre abonnement pour profiter de tous les services de Mes Services DUE
              </v-card-subtitle>

              <v-card-text>
                Nous sommes forcés de constater que vous n'avez pas encore souscrit à un abonnement. Pour profiter de
                tous les services de Mes Services DUE, veuillez choisir un abonnement ci-dessous.
              </v-card-text>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3" v-for="(offer, index) in offers" :key="index">
                    <v-card
                      outlined
                      width="250"
                      height="350"
                      class="rounded-xl d-flex flex-column justify-space-between"
                    >
                      <v-card-text>
                        <v-row class="d-flex flex-column align-center text-center">
                          <v-col cols="12">
                            <div
                              class="tier-title-container"
                              style="height: 90px; display: flex; flex-direction: column; justify-content: center"
                            >
                              <h3 style="font-size: 1.2rem" class="font-weight-bold EoleBlue--text">
                                {{ offer.designation }}
                              </h3>
                              <p v-if="offer.id !== 1" style="font-size: 1.1rem" class="EoleYellow--text">
                                {{ offer.price }} €
                              </p>
                              <p v-else style="font-size: 1.1rem" class="EoleYellow--text">50.00 €</p>
                            </div>
                            <div
                              class="description"
                              style="height: 60px; display: flex; align-items: center; justify-content: center"
                            >
                              <p>{{ offer.description }}</p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="d-flex justify-center">
                        <v-btn color="primary" @click="subscribe(offer.id)" rounded> Choisir cette offre </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
import { loadStripe } from "@stripe/stripe-js"

export default {
  data() {
    return {
      dialogNoPack: true,
      offers: [],
    }
  },
  async created() {
    this.getOffers()
  },
  methods: {
    async subscribe(offerId) {
      const idAgency = this.$store.state.user.agency_id
      try {
        const response = await fetch(`${config.apiUri}/agencies/${idAgency}/due/packs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            id_pack: offerId,
          }),
        })

        if (response.ok) {
          const { sessionId } = await response.json()

          // Redirection vers Stripe
          const stripe = await loadStripe(
            "pk_test_51P3JIlDQMkagpLizvGVoKxpk2tEaNrZ6c3tSn9ehhmGKQjj6q5M0ILEIbS0dKU3XzGP6IvXTLvaIslJI26de9Xyd00D7qWwQsm",
          )
          const { error } = await stripe.redirectToCheckout({ sessionId })
          if (error) {
            console.error("Error redirecting to Stripe:", error)
          }
        } else {
          console.error("Erreur lors de la création de la session de paiement")
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getOffers() {
      try {
        const response = await fetch(`${config.apiUri}/due/subscriptions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          const data = await response.json()
          this.offers = data
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style>
.tier-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  text-align: center;
}

.description {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  text-align: center;
}
</style>
